import React, { useState } from "react"
import _, { toUpper } from "lodash"
import moment from "moment"
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap"
import classNames from "classnames"
import { Link, useNavigate } from "react-router-dom"
import AssignCleanerComponent from "../AssignCleanerComponent"
import OnDemandSchedule from "../../../../components/OnDemandSchedule"
import { currencyConversion } from "../../../../helpers/currency"
import DailyCleaningPartnerAssignDetails from "../../../serviceRequests/components/DailyCleaningPartnerAssignDetails"

const BookingDetailsLeftCard = (props) => {
  const navigate = useNavigate()
  const data = props.data || {}
  // const addons = props.addons || []
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  let fillColor =
  data?.specialTask?.status === "Not Attempted" ||
  data?.task?.status === "Not Attempted"
    ? "#030201"
    : data?.specialTask?.status === "Completed" ||
      data?.task?.status === "Completed"
    ? "#2A882A"
    : data?.specialTask?.status === "Cleaner did not clean" ||
      data?.task?.status === "Cleaner did not clean"
    ? "#FF0000"
    : "#0181E3"

  let DailyFillColor = data?.status === "Active" ? "#2A882A" : "#0181E3"

  return (
    <>
      <Card>
        <Card.Body>
          <div>
            <Col xl={12} className="pb-3">
              <>
                <h5 className="">
                  <i className="mdi mdi-account  me-1"></i>Customer details
                </h5>
                <div className="ms-3">
                  <Row className="text-muted mb-1 ">
                    <Col md={3}>
                      <span
                        style={{
                          color: "#00000090",
                          fontSize: "16px",
                        }}
                      >
                        Name :
                      </span>
                    </Col>
                    <Col>
                      <Link
                        to={{
                          pathname: "/users/user-details",
                          search: `?id=${data?.customerId?._id}`,
                        }}
                        target="_blank"
                      >
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 text-decoration-underline "
                        >
                          {data?.customerId?.name}
                        </strong>
                        <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="text-muted font-1">
                    <Col md={3}>
                      <span
                        style={{
                          color: "#00000090",
                          fontSize: "16px",
                        }}
                      >
                        Number :
                      </span>
                    </Col>
                    <Col>
                      <strong
                        style={{
                          color: "#2b2d2f",
                          fontSize: "15px",
                        }}
                        className="ms-2 "
                      >
                        {data?.customerId?.phone}
                      </strong>
                    </Col>
                  </Row>
                </div>
              </>
              <>
                <div
                  className="d-flex mt-2"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <h5 className="">
                    <i className="mdi mdi-account mdi-clipboard-text me-1"></i>
                    Booking details
                  </h5>
                  {data?.isRescheduled ? (
                    <span className="badge ms-2 badge-warning-color bg-warning">
                      Rescheduled
                    </span>
                  ) : null}
                </div>
                <div className="ms-3">
                  <Row className="text-muted mb-1 ">
                    <Col md={3}>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#00000090",
                        }}
                      >
                        Status :
                      </span>
                    </Col>
                    <Col>
                      {data?.isSubscriptionModel ? (
                        <>
                          <span
                            className={
                              data.isCancelled
                                ? "badge ms-2 badge-danger-color bg-danger"
                                : data.isActive
                                  ? "badge ms-2 badge-success-color bg-success"
                                  : "badge ms-2 badge-warning-color bg-warning"
                            }
                            style={{ fontSize: 12 }}
                          >
                            {data.isCancelled
                              ? "Cancelled"
                              : data.isActive
                                ? "Active"
                                : "Incomplete"}
                          </span>
                        </>
                      ) : (
                        <>
                          {!data.isActive &&
                            data?.isClosed &&
                            data?.isCancelled ? (
                            <span
                              className="badge ms-2 badge-danger-color bg-danger"
                              style={{ fontSize: 12 }}
                            >
                              Cancelled
                            </span>
                          ) : (
                            <>
                              {data?.specialTask?.status ? (
                                <span>
                                  <strong
                                    style={{
                                      fontSize: 12,
                                      backgroundColor: fillColor,
                                    }}
                                    className="ms-2 badge"
                                  >
                                    {data?.specialTask?.status}{" "}
                                  </strong>
                                </span>
                              ) : (
                                <strong
                                  style={{
                                    fontSize: 12,
                                    backgroundColor: fillColor,
                                  }}
                                  className="ms-2 badge"
                                >
                                  Not scheduled
                                </strong>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  {false && data?.isRescheduled ? (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#00000090",
                          }}
                        >
                          Old Service Date :
                        </span>
                      </Col>
                      <Col>
                        <div
                          onClick={() => {
                            setShowRescheduleModal(true)
                          }}
                        >
                          <strong
                            style={{
                              color: "#2b2d2f",
                              fontSize: "15px",
                            }}
                            className="ms-2 text-decoration-underline "
                          >
                            Reschedule from date
                          </strong>
                          <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  {/* {data?.servicepackageId?.service_name && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Service name :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.servicepackageId?.name}
                        </strong>
                        {data?.isTrial_booking ? (
                          <Badge
                            bg="info"
                            style={{
                              fontSize: 12,
                              marginTop: -4,
                              marginLeft: 6,
                            }}
                          >
                            Free Trial
                          </Badge>
                        ) : null}
                     {data?.isBookingQueued && (
                          <Badge
                            bg="warning"
                            className="ms-1"
                            style={{
                              fontSize: 12,
                              marginTop: -4,
                              marginLeft: 6,
                            }}
                          >
                            In Queue
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  )} */}
                    {data?.servicepackageId?.name && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Service name :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.servicepackageId?.name}
                        </strong>
                        {data?.isTrial_booking ? (
                          <Badge
                            bg="info"
                            style={{
                              fontSize: 12,
                              marginTop: -4,
                              marginLeft: 6,
                            }}
                          >
                            Free Trial
                          </Badge>
                        ) : null}
                        {data?.isBookingQueued && (
                          <Badge
                            bg="warning"
                            className="ms-1"
                            style={{
                              fontSize: 12,
                              marginTop: -4,
                              marginLeft: 6,
                            }}
                          >
                            In Queue
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  )}
                  {data?.servicepackageId?.service_name && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Plan :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.quantity}{" "}
                          {data?.quantity > 1 ? "Months" : "Month"}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.conversion_plan && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Conversion plan :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.conversion_plan}{" "}
                          {data?.conversion_plan > 1 ? "months" : "month"}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.timeSlot?.name && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Time slot :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.timeSlot?.name}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.service_booking_date && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                         {data?.swap_booking_id && !data?.isCancelled? "Swap Date" : "Booking Date"}
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {moment(data?.service_booking_date).utc().utcOffset(240).format(
                            "DD MMM, YYYY"
                          )}
                        </strong>
                      </Col>
                    </Row>
                  )}
                    {/* {moment(data?.service_reactivation_date).isSame(
                    moment(data?.service_start_date)
                  ) && (
                      <>
                        {data?.data?.service_start_date && (
                          <Row className="text-muted mb-1 ">
                            <Col md={3}>
                              <span
                                style={{
                                  color: "#00000090",
                                  fontSize: "16px",
                                }}
                              >
                                Start Date :
                              </span>
                            </Col>
                            <Col>
                              <strong
                                style={{
                                  color: "#2b2d2f",
                                  fontSize: "15px",
                                }}
                                className="ms-2 "
                              >
                                {moment(data?.service_reactivation_date).format(
                                  "DD MMM, YYYY"
                                )}
                              </strong>
                            </Col>
                          </Row>
                        )}
                      </>
                    )} */}
                       {data?.swap_booking_id && (
                    <Link
                    to={{
                      pathname: "/bookings/assigned-bookings",
                      search: `?id=${data?.swap_booking_id}`,
                    }}
                    target="_blank"
                    >
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          className="text-primary"
                          style={{
                            fontSize: "16px",
                            textDecoration: "underline",
                          }}
                        >
                          {data?.isCancelled?"New Booking":"Previous Booking"}
                          <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1 text-primary"></i>
                        </span>
                      </Col>
                    </Row>
                    </Link>
                  )}
                  {data?.isSubscriptionModel || moment(data?.service_reactivation_date).isSame(
                    moment(data?.service_start_date)
                  ) ? (
                    <>
                      {data?.service_start_date && (
                        <Row className="text-muted mb-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Start Date :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{
                                color: "#2b2d2f",
                                fontSize: "15px",
                              }}
                              className="ms-2 "
                            >
                              {moment(data?.service_start_date).utc().utcOffset(240).format(
                                "DD MMM, YYYY"
                              )}
                            </strong>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : (
                    <>
                      {data?.specialTask?.due_date && (
                        <div>
                          <Row className="text-muted mb-1 ">
                            <Col md={3}>
                              <span
                                style={{
                                  color: "#00000090",
                                  fontSize: "16px",
                                }}
                              >
                                {data?.serviceType == "cleaning"
                                  ? "Start Date :"
                                  : "Service Date :"}
                              </span>
                            </Col>
                            <Col>
                              <strong
                                style={{
                                  color: "#2b2d2f",
                                  fontSize: "15px",
                                }}
                                className="ms-2 "
                              >
                                {moment(data?.specialTask?.due_date).utc().utcOffset(240).format(
                                  "DD MMM, YYYY"
                                )}
                              </strong>
                            </Col>
                          </Row>
                          {data?.serviceType == "on-demand" ? (
                            <Row>
                              <Col md={3}>
                                <span
                                  style={{
                                    color: "#00000090",
                                    fontSize: "16px",
                                  }}
                                >
                                  Service Time :
                                </span>
                              </Col>
                              <Col>
                                <strong
                                  style={{
                                    color: "#2b2d2f",
                                    fontSize: "15px",
                                  }}
                                  className="ms-2 "
                                >
                                  {data?.serviceType != "cleaning"
                                    ? moment()
                                      .utc().utcOffset(240)
                                      .startOf("day")
                                      .add(data?.serviceMinute, "minutes")
                                      .format("LT")
                                    : null}
                                </strong>
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                      )}
                    </>
                  )}
                
                  {data?.isHold && <></>}
                  {data?.isCancelled && data?.cancelledOn && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Cancelled Date :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {moment(data?.cancelledOn).utc().utcOffset(240).format("DD MMM, YYYY")}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.serviceType == "cleaning" && data?.expiry_date && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Expiry Date :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {moment(data?.expiry_date).utc().utcOffset(240).format("DD MMM, YYYY")}
                        </strong>
                      </Col>
                    </Row>
                  )}
                </div>
              </>
              {data?.paidAddons?.length > 0 ? (
                <Col lg={8} md={8}>
                  <div className="">
                    <h5 className="mt-3">
                      <i className="mdi mdi-account mdi-note-plus me-1"></i>
                      Addon details
                      <table className="table table-bordered mt-2">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.paidAddons?.length > 0 &&
                            data?.paidAddons?.map((addon, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ fontWeight: "normal" }}>
                                    {index + 1}
                                  </td>
                                  <td>
                                    <span style={{ fontWeight: "normal" }}>
                                      {addon.paidAddonTypeId?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span style={{ fontWeight: "normal" }}>
                                      OMR{" "}
                                    </span>
                                    <span style={{ color: "black" }}>
                                      {currencyConversion(addon.addon_cost)}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </h5>
                  </div>
                </Col>
              ) : null}
              {data?.task?._id || data?.specialTask?._id ? (
                <div>
                  <Link
                    to={
                      data?.isSubscriptionModel
                        ? {
                          pathname: "/jobs/daily-cleaning",
                          search: `?id=${data?.task?._id}`,
                        }
                        : {
                          pathname: "/jobs/on-demand",
                          search: `?id=${data?.specialTask?._id}`,
                        }
                    }
                  >
                    <div
                      className="d-flex mt-2"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <h5 className="">
                        <i className="mdi mdi-account mdi-clipboard-text me-1"></i>
                        Job details
                        <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                      </h5>
                    </div>
                  </Link>
                  <div className="ms-3">
                    {data?.isSubscriptionModel && (
                      <Row className="text-muted mb-1 ">
                        <Col md={3}>
                          <span
                            style={{
                              color: "#00000090",
                              fontSize: "16px",
                            }}
                          >
                            Todays job status :
                          </span>
                        </Col>
                        <Col>
                          <span
                            className="ms-2 badge"
                            style={{
                              fontSize: "12px",
                              backgroundColor: fillColor,
                            }}
                          >
                            {data?.task?.status}
                          </span>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              ) : null}
              <>
                <div className="d-flex flex-row align-items-center">
                  <h5 className="mt-3">
                    <i className="mdi mdi-account-wrench me-1"></i>Partner
                    details
                    {data?.swap_booking_id && (
                      <Badge
                        bg="primary"
                        className="ms-1"
                        style={{
                          fontSize: 12,
                          marginTop: -4,
                          marginLeft: 6,
                        }}
                      >
                        Swap Partner
                      </Badge>
                    )}
                  </h5>
                  {data?.toReassign ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "14px",
                      }}
                    >
                      <span className="badge bg-warning ms-2">To Reassign</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            <p style={{ margin: 0 }}>
                              Previous Partner:{" "}
                              <strong>
                                {data?.reassignlogs[0]?.cleanerId?.name}
                              </strong>
                            </p>
                            <p style={{ margin: 0 }}>
                              Reason:
                              <strong>{data?.reassignlogs[0]?.reason}</strong>
                            </p>
                          </Tooltip>
                        }
                      >
                        <i className="mdi mdi-information-outline ms-1"></i>
                      </OverlayTrigger>
                    </div>
                  ) : null}
                </div>
                <div className="ms-3">
                  {data?.agencyId?.role === "AGENCY_OWNER" ? (
                    <>
                      {data?.agencyId?.name && (
                        <Row className="text-muted font-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Agency Name :
                            </span>
                          </Col>
                          <Col>
                            <Link
                              to={{
                                pathname: "/partners/partner-profile",
                                search: `?id=${data?.agencyId?.ownerId}`,
                              }}
                            // target="_blank"
                            >
                              <strong
                                style={{
                                  color: "#00000090",
                                }}
                                className="ms-2 text-decoration-underline "
                              >
                                {data?.agencyId?.name}
                              </strong>
                              <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                            </Link>
                          </Col>
                        </Row>
                      )}
                      {data?.agencyId?.owner_phone && (
                        <Row className="text-muted mb-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Agency number :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{
                                color: "#00000090",
                              }}
                              className="ms-2 "
                            >
                              {data?.agencyId?.owner_phone}
                            </strong>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : null}

                  {data?.assigned_cleaner_id ? (
                    <>
                      <Row className="text-muted mb-1 font-1 ">
                        <Col md={3}>
                          <span
                            style={{
                              color: "#00000090",
                              fontSize: "16px",
                            }}
                          >
                            Name :
                          </span>
                        </Col>
                        <Col>
                          <Link
                            to={{
                              pathname:
                                data?.assigned_cleaner_id?.role ===
                                  "AGENCY_PARTNER"
                                  ? "/partners/staff-details"
                                  : "/partners/partner-profile",
                              search: `?id=${data?.assigned_cleaner_id?._id}`,
                            }}
                            target="_blank"
                          >
                            <strong
                              style={{
                                color: "#2b2d2f",
                                fontSize: "15px",
                              }}
                              className="ms-2 text-decoration-underline "
                            >
                              {data?.assigned_cleaner_id?.name}
                            </strong>
                            <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                          </Link>
                        </Col>
                      </Row>
                      {data?.assigned_cleaner_id?.phone && (
                        <Row className="text-muted mb-1 font-1">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Number :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{
                                color: "#2b2d2f",
                                fontSize: "15px",
                              }}
                              className="ms-2 "
                            >
                              {data?.assigned_cleaner_id?.phone}
                            </strong>
                          </Col>
                        </Row>
                      )}
                      {/* {data?.assigned_cleaner_id?.role && (
                        <Row className="text-muted mb-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Partner type :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{fontSize: 10}}
                              className="badge badge-outline-primary ms-2"
                            >
                              {data?.assigned_cleaner_id?.role === "INDEPENDENT"
                                ? "Independent"
                                : "Agency"}
                            </strong>
                          </Col>
                        </Row>
                      )} */}
                      {data?.specialTask?.start_job_otp && (
                        <Row className="text-muted pb-1 font-1 ">
                          <Col xl={3}>
                            <span style={styles.text}>Start job OTP :</span>
                          </Col>
                          <Col>
                            <strong style={styles.textBlack} className="ms-2 ">
                              {data?.specialTask?.start_job_otp}
                            </strong>
                          </Col>
                        </Row>
                      )}
                      {/* {data?.specialTask?.end_job_otp && (
                      <Row className="text-muted pb-1 font-1 ">
                        <Col xl={3}>
                          <span style={styles.text}>End job OTP :</span>
                        </Col>
                        <Col>
                          <strong style={styles.textBlack} className="ms-2 ">
                            {data?.specialTask?.end_job_otp}
                          </strong>
                        </Col>
                      </Row>
                    )} */}
                      <>
                        {data?.isScheduled && !data?.isCancelled && (
                          <div>
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#008CBA",
                                color: "white",
                                borderRadius: 20,
                                height: 30,
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                marginTop: 10,
                                marginLeft: 6,
                              }}
                              onClick={() => {
                                if (
                                  data?.serviceType == "cleaning" ||
                                  data?.serviceType == "addon"
                                ) {
                                  setShowAssignModal(true)
                                } else {
                                  props?.handleShowAvailablePartners(true)
                                }
                              }}
                            >
                              {data.assigned_cleaner_id
                                ? " Reassign Partner"
                                : " Assign Partner"}
                            </button>
                          </div>
                        )}
                      </>
                    </>
                  ) : (
                    <>
                      <strong
                        style={{ fontSize: 12, backgroundColor: "#FF000099" }}
                        className="badge"
                      >
                        Partner Not Assigned
                      </strong>
                      {props?.isFromPaymentPendingBookings ? null :
                        (data?.isScheduled && !data?.isCancelled && (
                          <div>
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#008CBA",
                                color: "white",
                                borderRadius: 20,
                                height: 30,
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                marginTop: 10,
                              }}
                              onClick={() => {
                                if (
                                  data?.serviceType == "cleaning" ||
                                  data?.serviceType == "addon"
                                ) {
                                  setShowAssignModal(true)
                                } else {
                                  props?.handleShowAvailablePartners(true)
                                }
                              }}
                            >
                              {data.assigned_cleaner_id
                                ? "Reassign Partner"
                                : "Assign Partner"}
                            </button>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </>
            </Col>
            {showAssignModal && (
              <DailyCleaningPartnerAssignDetails
                showAssignModal={showAssignModal}
                setShowAssignModal={setShowAssignModal}
                data={data}
                updateBooking={props.getData}
              />
            )}
            {/* {showAssignModal && (
              <AssignCleanerComponent
                name={
                  data.assigned_cleaner_id
                    ? "Reassign Partner"
                    : "Assign Partner"
                }
                data={data}
                onHide={() => {
                  setShowAssignModal(false)
                }}
                getData={props.getData}
                reload={() => {
                  setShowAssignModal(false)
                }}
              />
            )} */}
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={showRescheduleModal}
        onHide={() => {
          setShowRescheduleModal(false)
        }}
        size="lg"
      >
        <Modal.Header>Reschedule Details</Modal.Header>
        <Modal.Body>
          <h4>Recent Reschedule Details</h4>
          <Row>
            <Col md={4}>Reschedule on :</Col>
          </Row>
          <Row>
            <Col md={4}>Rescheduled from :</Col>
          </Row>
          <Row>
            <Col md={4}>Rescheduled to :</Col>
          </Row>
          <Row>
            <Col md={4}>Reschedule by :</Col>
          </Row>
          <Row>
            <Col md={4}>Reschedule reason :</Col>
          </Row>
          <div>
            <h3>Reschedule History</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Reschedule on</th>
                  <th>Reschedule from</th>
                  <th>Reschedule to</th>
                  <th>by</th>
                  <th>reason</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>10-12-2023</td>
                  <td>
                    <span>10-12-2023</span>
                    <small className="ms-1">10:30 PM</small>
                  </td>
                  <td>
                    <span>10-12-2023</span>
                    <small className="ms-1">10:30 PM</small>
                  </td>
                  <td>admin</td>
                  <td>reason</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const styles = {
  text: {
    color: "#00000090",
    fontSize: "16px",
  },
  textBlack: {
    color: "#2b2d2f",
    fontSize: "15px",
  },
}

export default BookingDetailsLeftCard
