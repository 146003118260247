import React, {useState, useEffect, useRef} from "react"
import HyperDatepicker from "../components/Datepicker"
import {Modal, Row} from "react-bootstrap"
import moment from "moment"
import swal from "sweetalert"
import callApi from "../utils/apiCaller"
import {FormInput} from "../components"
import _, {set} from "lodash"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"

const OnDemandSchedule = (props) => {
  const data = props.data ? props.data : {}
  const calenderRef = useRef(null)
  const [signInModal, setSignInModal] = useState(true)
  const [scheduleDate, setScheduleDate] = React.useState(
    !_.isEmpty(props?.data)
      ?   moment(props?.data?.service_start_date).utc().utcOffset(240).format('DD-MM-YYYY')
      // moment(props?.data?.service_start_date).toDate()
    

      : new Date()
  )
  const [timeSlots, setTimeSlots] = React.useState([])
  const [scheduleTime, setScheduleTime] = React.useState(null)
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState(null)
  const [numberOfAddons, setNumberOfAddons] = React.useState(0)
  const [event, setEvent] = useState([])
  const [showDateRangeModal, setShowDateRangeModal] = useState(false)
  const [dateForSchedule, setDateForSchedule] = useState(null)

  const getTimeSlots = () => {
    callApi(`servicebooking/gettimeslot`, "POST", {
      servicepackageId: data?.servicepackageId
        ? data?.servicepackageId?._id
        : props?.bookingData?.servicepackageId?.addons[0]?.id?._id ||
          props?.bookingData?.servicepackageId?._id,
    }).then((res) => {
      if (res.data) {
        setTimeSlots(res.data)
      }
    })
  }

  useEffect(() => {
    getTimeSlots()
    getEvents()
  }, [])

  useEffect(() => {
    // getNumberOfAddonForDate()
  }, [scheduleDate])

  const getNumberOfAddonForDate = () => {
    let reqData = {
      date: scheduleDate,
      cleanerId: data?.assigned_cleaner_id
        ? data?.assigned_cleaner_id?._id
        : props?.bookingData?.assigned_cleaner_id?._id,
      servicepackageId:
        props?.bookingData?.servicepackageId?.addons[0]?.id?._id,
    }
    callApi("ondemand/addonJobCount", "post", reqData)
      .then((res) => {
        if (res?.success) {
          setNumberOfAddons(res?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const scheduleBooking = () => {
    if (dateForSchedule && selectedTimeSlot) {
      const info = {}
      if (data?._id && data?.isScheduled) {
        info.servicebookingId = data?._id
        info.rescheduled_date = moment(dateForSchedule).utc().utcOffset(240).format("YYYY-MM-DD")
        info.rescheduled_timeSlot =
          data?.serviceType != "on-demand"
            ? selectedTimeSlot
            : moment(selectedTimeSlot, "HH:mm").diff(
                moment().startOf("day"),
                "minutes"
              )


        info.serviceType = data?.serviceType
      } else {
        info.servicebookingId = props?.bookingData?._id
        info.date = moment(dateForSchedule).utc().utcOffset(240).format("YYYY-MM-DD")
        info.timeSlotId = selectedTimeSlot
      }
      const url = data?.isScheduled
        ? "ondemand/reschedule"
        : "servicebooking/createAndScheduleAddon"

      const name = data?.isScheduled ? "re shedule" : "schedule"

      swal({
        title: `Do you want to ${name} booking `,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          callApi(url, "POST", info).then((res) => {
            if (res.success) {
              setSignInModal(false)
              props.getData(props?.bookingData?._id)
              swal(
                "Success",
                `Booking ${
                  data?.isScheduled ? "rescheduled" : "scheduled"
                } successfully`,
                "success"
              )
            } else {
              setSignInModal(false)
              swal("Error", res.message, "error")
            }
          })
        }
      })
    } else {
      swal("Error", "Please select date and time slot", "error")
    }
  }

  const getEvents = (givenDate) => {
    let reqData = {
      cleanerId: data?.assigned_cleaner_id
        ? data?.assigned_cleaner_id?._id
        : props?.bookingData?.assigned_cleaner_id?._id,
      servicebookingId: props?.bookingData?._id,
      date: givenDate ? givenDate : new Date(),
    }
    callApi("ondemand/addonScheduleCalander", "post", reqData)
      .then((res) => {
        if (res?.success) {
          console.log("res----", res)
          // addonCount is the number of addons scheduled for the day of total perDayLimit
          // addonCount will be 0 if there is no addon scheduled for the day
          // perDayLimit is number is
          // if there is holiday isHoliday will be true
          // let newDates = res?.data?.map((i) => {
          //   let availableAddon = i?.perDayLimit - i?.addonCount
          //   return {
          //     start: i.date,
          //     title: i.isHoliday
          //       ? "Holiday"
          //       : i?.addonCount === i?.perDayLimit &&
          //         i?.addonCount > 0 &&
          //         i?.perDayLimit > 0
          //       ? "Full"
          //       : `${availableAddon} Available out of ${i?.perDayLimit}`,
          //     backgroundColor: i.isHoliday
          //       ? "#6d6d6d"
          //       : i?.addonCount > 1
          //       ? "#d32f2f"
          //       : "#2a882a",
          //   }
          // })
          // setEvent(newDates)

          let newDates = res?.data?.flatMap((i) => {
            const dayFull = i?.addonCount >= i?.perDayLimit

            // Event for availableAddon
            let event1 = {
              start: i.date,
              title: i.isHoliday
                ? "Holiday"
                : `${i?.addonCount} Scheduled out of ${i?.perDayLimit}`,
              backgroundColor: i.isHoliday
                ? "#6d6d6d"
                : dayFull
                ? "#d32f2f"
                : "#2a882a",
              addonCount: i?.addonCount,
              isHoliday: i.isHoliday,
              perDayLimit: i?.perDayLimit,
            }

            // Event for full addon count
            let event2 =
              i?.addonCount > 0 && i.isHoliday
                ? {
                    start: i.date,
                    title: `${i?.addonCount} Scheduled`,
                    backgroundColor: "#2a882a",
                  }
                : null // Set to null if no second event is needed

            return [event1, event2].filter(Boolean) // Filter out null events
          })

          setEvent(newDates)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDateClick = (arg) => {
    setDateForSchedule(arg?.event?.start)
    setShowDateRangeModal(false)
  }

  const handleDateFullClick = (arg) => {
    setDateForSchedule(arg?.date)
    setShowDateRangeModal(false)
  }

  const handlePrev = () => {
    calenderRef.current.getApi().prev()
    let calendarApi = calenderRef.current.getApi()
    let date = calendarApi.getDate()
    getEvents(new Date(date))
  }

  const handleToday = () => {
    calenderRef.current.getApi().today()
    let calendarApi = calenderRef.current.getApi()
    let date = calendarApi.getDate()
    getEvents(new Date(date))
  }

  const handleNext = () => {
    calenderRef.current.getApi().next()
    let calendarApi = calenderRef.current.getApi()
    let date = calendarApi.getDate()
    getEvents(new Date(date))
  }

  return (
    <Modal
      show={signInModal}
      onHide={() => {
        setSignInModal(!signInModal)
        props.onHide()
      }}
      size="md"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title ">
          {data?.isScheduled ? "Reschedule Addon" : "Schedule New Addon"}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-2 mb-4">
          <Modal
            show={showDateRangeModal}
            onHide={() => {
              setShowDateRangeModal(false)
            }}
            size="xl"
          >
            <Modal.Header closeButton>
              <h4 className="modal-title ">Calender</h4>
            </Modal.Header>
            <Modal.Body>
              <FullCalendar
                contentHeight={700}
                initialDate={moment().utc().utcOffset(240).format("YYYY-MM-DD")}
                initialView="dayGridMonth"
                plugins={[dayGridPlugin, interactionPlugin]}
                handleWindowResize={true}
                themeSystem="bootstrap"
                buttonText={{
                  today: "Today",
                  month: "Month",
                  prev: "Prev",
                  next: "Next",
                }}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "",
                }}
                editable={true}
                selectable={true}
                droppable={true}
                // height={600}
                // width={600}
                events={event}
                firstDay={1}
                ref={calenderRef}
                eventClick={handleDateClick}
                customButtons={{
                  prev: {
                    click: handlePrev,
                  },
                  next: {
                    click: handleNext,
                  },
                  today: {
                    text: moment().utc().utcOffset(240).format("MMMM"),
                    click: handleToday,
                  },
                }}
                dateClick={handleDateFullClick}
                eventContent={(arg, createElement) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={styles.dot} />
                        {/* <div style={{fontSize: 12}}>{arg.event.title}</div> */}
                        {arg.event.extendedProps.isHoliday ? (
                          <span className="" style={{fontSize: 14}}>
                            {arg.event.title}
                          </span>
                        ) : (
                          <span className="">
                            <span className="fw-bold " style={{fontSize: 14}}>
                              {arg.event.extendedProps.addonCount}
                            </span>{" "}
                            <span className="fw-bold">Scheduled</span> out of{" "}
                            <span className="fw-bold" style={{fontSize: 14}}>
                              {arg.event.extendedProps.perDayLimit}
                            </span>
                          </span>
                        )}
                      </div>
                    </>
                  )
                }}
                dayCellContent={(arg) => {
                  return (
                    <div
                      style={{
                        fontSize: 10,
                        padding: "2px",
                        height: "30px",
                        lineHeight: "1.2",
                      }}
                    >
                      {arg.dayNumberText}
                    </div>
                  )
                }}
              />
            </Modal.Body>
          </Modal>
          <div className="">
            <p className="fw-bold">Select Date</p>
            <Row>
              <div md={12}>
                <div
                  className="d-flex align-items-center"
                  style={{
                    ...styles.dateText,
                    backgroundColor: dateForSchedule ? "#EEF2F6" : "#fff",
                  }}
                >
                  <div
                    style={styles.dateContainer}
                    onClick={() => {
                      setShowDateRangeModal(true)
                    }}
                  >
                    <span className="mr-2">
                      {dateForSchedule
                        ? moment(dateForSchedule).utc().utcOffset(240).format("dddd, DD MMM, YYYY")
                        : "--Select Scheduled Date--"}
                    </span>
                  </div>
                  {dateForSchedule ? (
                    <div
                      style={styles.closeButton}
                      onClick={() => {
                        setDateForSchedule(null)
                      }}
                    >
                      <i className="mdi mr-1 dripicons-cross"></i>
                    </div>
                  ) : null}
                </div>
              </div>
            </Row>
            {/* <HyperDatepicker
              hideAddon={true}
              value={scheduleDate}
              onChange={(date) => {
                setScheduleDate(date)
              }}
            /> */}
            {/* <p className="text-danger">
              {numberOfAddons?.addonCount} addons scheduled for the day of total{" "}
              {numberOfAddons?.perDayLimit}
            </p> */}
            <div className="mt-3">
              <FormInput
                label="Select timeslot"
                type="select"
                name="partnerStatus"
                placeholder="Partner Status"
                key="partnerStatus"
                value={scheduleTime}
                onChange={(e) => {
                  setScheduleTime(e.target.value)
                  setSelectedTimeSlot(e.target.value)
                }}
              >
                <option>Select time slot</option>
                {timeSlots.map((time, index) => (
                  <option key={index} value={time._id}>
                    {time.name}
                  </option>
                ))}
              </FormInput>
            </div>
          </div>
          <div className="mb-3 text-center mt-4">
            <button
              onClick={() => {
                scheduleBooking()
              }}
              className="btn btn-rounded btn-primary"
            >
              {data?.isScheduled ? "Update" : "Schedule"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default OnDemandSchedule

const styles = {
  dot: {
    height: 10,
    width: 10,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 5,
  },
  dateText: {
    border: "1px solid #E5E5E5",
    borderRadius: 5,
    // cursor: "pointer",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    padding: 8,
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    cursor: "pointer",
    padding: 8,
  },
}
